<template>
    <footer class="application-footer">
        <div class="footer-top my-container">
            <div class="footer-left">
                <NuxtLink :to="localePath('/')" @click="scrollToTop">
                    <img src="/logo.png" alt="Logo" class="footer-logo">
                </NuxtLink>
                <p class="footer-tagline">{{ t('footerTitle') }}</p>
            </div>
            <form class="contact-form" @submit.prevent="sendForm" id="contact-us-footer">
                <div class="footer-form_header">{{ t('contactUs') }}</div>
                <BaseInput :placeholder="t('yourName')" type="text" v-model="form.name"
                    :class="{ error: form.nameError }" />
                <BaseInput :placeholder="t('yourPhone')" type="tel" v-model="form.phone"
                    :class="{ error: form.phoneError }" />

                <button type="submit" class="my-btn">{{ t('send') }}</button>
            </form>
        </div>
        <div class="divider"></div>
        <div class="footer-bottom my-container">
            <div class="footer-column">
                <div class="section-title">{{ t('sections') }}</div>
                <ul>
                    <li>
                        <NuxtLink :to="localePath('/catalog/category')">{{ t('products') }}</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink :to="localePath('/about')">{{ t('aboutCompany') }}</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink :to="localePath('/vacancy')">{{ t('vacancy') }}</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink :to="localePath('/news')">{{ t('newsAndPromos') }}</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink :to="localePath('/contacts')">{{ t('contacts') }}</NuxtLink>
                    </li>
                </ul>
            </div>
            <div class="footer-column">
                <div class="section-title">{{ t('information') }}</div>
                <ul>
                    <li>
                        <NuxtLink to="https://usoft.uz/?utm_source=eclair_uz&utm_medium=footer_link" target="blank">{{ t('ads') }}</NuxtLink>
                    </li>
                    <li class="no-hover">{{ t('copyright') }}</li>
                    <li>
                        <NuxtLink v-if="state.home.contacts_section.telegram" :to="state.home.contacts_section.telegram"
                            target="blank">{{ t('ourTelegram') }}</NuxtLink>
                    </li>
                </ul>
            </div>


            <div class="footer-column" v-if="state.home">
                <div class="section-title">{{ t('contacts') }}</div>
                <ul>
                    <li>{{ state.home.contacts_section.email }}</li>
                    <li>{{ state.home.contacts_section.phone }}</li>
                    <li>{{ state.home.contacts_section.address[locale] }}</li>
                </ul>
            </div>

            <div class="footer-column" v-if="state.home">
                <a class="social-icon" :href="social.link" v-for="social in state.home.contacts_section.socials"
                    :key="social.link">
                    <img :src="social.icon" alt="social-link">
                </a>

            </div>
        </div>
    </footer>
</template>

<script setup>

const { locale, t } = useI18n()
const localePath = useLocalePath();
const toast = useToast()
const rc = useRuntimeConfig()
const { start, finish } = useLoadingIndicator()

const { initStore, state } = useMainStore()
await initStore();
const form = reactive({
    name: '',
    phone: '',

    nameError: false,
    phoneError: false,
})
async function sendForm() {
    form.nameError = !form.name.trim();
    form.phoneError = !form.phone.trim();

    if (form.nameError || form.phoneError) {
        return toast.add({
            title: t('fillForm'),
            color: 'red'
        });
    }
    const body = {
        name: form.name,
        phone: form.phone
    }
    try {
        start()
        await $fetch(rc.public.api + '/api/application/contact-us', {
            method: 'POST',
            body
        })
        toast.add({
            title: t('contactSuccess'),
        })

        resetForm();
    } catch (error) {
        toast.add({
            title: error?.data?.context?.message || error?.data?.message || 'Internal error',
            color: 'red'
        })
    } finally {
        finish()
    }

}
function scrollToTop() {
    window.scrollTo({ top: 0 })
}
function resetForm() {
    form.name = form.phone = '';
    form.nameError = form.phoneError = false
}
</script>